<template>
  <div class="pagination-view-mode">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="data.page"
          :page-sizes="[5, 10, 15]"
          :page-size="data.count"
          layout="total, sizes, prev, pager, next, jumper"
          :total="data.total"
        >
        </el-pagination>
  </div>
</template>

<script>

export default {
    name: 'PaginationViewMode',
    props: ['data', 'onSizeChange', 'onCurrentChange'],
    methods:{
      handleSizeChange(val) {
        this.$emit('onSizeChange', val);
        
      },
      handleCurrentChange(val) {
        this.$emit('onCurrentChange', val);
      }
    }
}
</script>

<style lang="less" scope>
.pagination-view-mode{
  .el-pagination{
    display: flex;
    justify-content: flex-end;
    color: #fff;
    .el-dialog, .el-pager li {
      width: 32px;
      height: 32px;
      background: rgba(76, 156, 255, 0.2);
      border: 1px solid #3C79B1;
      border-radius: 3px;
      &.active{
        background: rgba(125, 235, 255, 0.2);
        border: 1px solid #7DEBFF;
      }
    }
    .el-pager li + li{
      margin: 0 5px 0 5px;
    }
    .el-pager li:first-child{
      margin: 0 0 0 10px;
    }
    .el-pager li:last-child{
      margin: 0 10px 0px 5px;
    }
    .el-pager li.btn-quicknext, .el-pager li.btn-quickprev{
      color: #fff;
    }
    .btn-prev, .btn-next{
      width: 32px;
      height: 32px;
      background: rgba(76, 156, 255, 0.2);
      border: 1px solid #3C79B1;
      color: #3C79B1;
      border-radius: 3px;
    }
    .el-pagination__jump{
      height: 32px;
      line-height: 32px;
      color: #fff;
      .el-input__inner{
        width: 32px;
        background: rgba(76, 156, 255, 0.2);
        border: 1px solid #3C79B1;
        color: #3C79B1;
        border-radius: 3px;
        color: #fff;
      }
    }
    .el-pagination__sizes{
      height: 32px;
      line-height: 32px;
      .el-input__inner{
        background: rgba(76, 156, 255, 0.2);
        border: 1px solid #3C79B1;
        color: #3C79B1;
        border-radius: 3px;
        color: #fff;
      }

    }
    .el-pagination__total{
      height: 32px;
      line-height: 32px;
      color: #fff;
    }
  }

}

.el-select-dropdown.el-popper{
  background: rgba(4, 17, 34, 0.9);
  border: 1px solid #3c79b1;
  border-radius: 5px;
  color: #fff;
  .el-select-dropdown__list {
    .el-select-dropdown__item {
      height: 30px;
      color: #fff;
      line-height: 30px;
    }
    .el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
  .popper__arrow {
    border-top-color: #3c79b1 !important;
    &::after {
      border-top-color: rgba(4, 17, 34, 0.9) !important;
    }
  }
}
</style>
