<template>
  <div class="table-mode" :style="{ width: tableWidth }" :class="className">
    <el-table :data="tableData" :row-class-name="tableRowClassName" height="500">
     <slot></slot>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "TableMode",
  props: ['tableData', 'className'],
  data() {
    return {
      tableWidth: "0px",
    };
  },
  mounted() {
    this.initTableWidth();
    window.addEventListener("resize", this.initTableWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.initTableWidth);
  },
  methods: {
    initTableWidth() {
      const { width } = document.body.getBoundingClientRect();
      this.tableWidth = `${width - 375}px`;
    },
    tableRowClassName({ rowIndex }) {
      return rowIndex % 2 ? "odd-row" : "even-row";
    },
  },
};
</script>

<style lang="less">
.table-mode {
  margin-top: 10px;
  flex: 1 1 auto;
  .el-table {
    font-size: 12px;
    background-color: transparent;
    &::before {
      height: 0;
    }
    th.el-table__cell,
    tr {
      background-color: transparent;
    }
    td.el-table__cell {
      border-bottom: none;
    }
    .el-table__cell {
      padding: 10px 0;
    }
    .cell {
      color: #fff;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .odd-row {
      background: rgba(255, 255, 255, 0.1);
    }
    tr:hover > td.el-table__cell {
      background: rgba(22, 219, 255, 0.1);
    }
    .el-table__row {
      .cell {
        .num {
          color: #7debff;
          font-weight: bold;
          font-size: 14px;
          text-decoration: underline;
          cursor: pointer;
          &:hover {
            color: rgb(234, 237, 245);
          }
        }
      }
    }
  }
}

.table-tooltips {
  background: rgba(4, 17, 34, 0.7);
  border: 1px solid #3c79b1;
  border-radius: 5px;
  color: #fff;
  .popper__arrow {
    border-bottom-color: #3c79b1 !important;
    &::after {
      border-bottom-color: rgba(4, 17, 34, 0.9) !important;
    }
  }
}
</style>